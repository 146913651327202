import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const WorkTimeline = ({ lineHeight = "48px" }) => {
  return (
    <div>
      <h1 className="cv__header">
        <BusinessCenterIcon sx={{ fontSize: "48px", marginBottom: "10px" }} />{" "}
        Experience{" "}
      </h1>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.5,
          },
        }}
      >
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2024 - Present)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ width: "0px" }} />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">Senior AI Engineer</div>
            <div className="cv__desc">SLB (ex-Schlumberger)</div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2021 - 2024)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">Artificial Intelligence Engineer</div>
            <div className="cv__desc">SLB (ex-Schlumberger)</div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2020)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">Data Scientist Intern</div>
            <div className="cv__desc">Bouygues Telecom</div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
};

export default WorkTimeline;
