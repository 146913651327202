import "./home.css";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  SiPython,
  SiPytorch,
  SiPandas,
  SiScikitlearn,
  SiReact,
  SiJavascript,
  SiAzuredevops,
  SiGit,
  SiGooglescholar,
} from "react-icons/si";
import Grid from "@mui/material/Grid";

const Home = () => {
  return (
    <div>
      <Grid
        container
        columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}
        maxWidth={{ md: "900px", lg: "1300px" }}
        margin="auto"
        justify="center"
        style={{
          maxHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid
          item
          xs={6}
          padding="10px"
          margin={{
            xs: "30px auto auto auto",
            sm: "30px auto auto auto",
            md: "30px auto auto auto",
            lg: "10% auto auto auto",
          }}
        >
          <Row className="home__row__photo">
            <Image className="home__photo" src="me.jpg" />
          </Row>
          <Row className="home__row__logos">
            <Col>
              <a href="https://www.linkedin.com/in/aymeric-jan-9780b3175/">
                <LinkedInIcon className="home__linkedin" />
              </a>
            </Col>
            <Col>
              <a href="https://scholar.google.com/citations?user=SHeUdQwAAAAJ&hl=fr">
                <SiGooglescholar className="home__linkedin" />
              </a>
            </Col>
          </Row>
        </Grid>
        <Grid
          item
          xs={6}
          padding={"10px"}
          margin={{
            xs: "auto auto 200px auto",
            sm: "auto auto 200px auto",
            md: "auto auto 200px auto",
            lg: "5% auto 200px auto",
          }}
        >
          <h1 className="home__name">Aymeric JAN </h1>
          <h3 className="home__section">Positions</h3>
          <h4 className="home__position">Senior AI Engineer</h4>
          <div className="home__info">
            <LocationOnIcon></LocationOnIcon> Paris, SLB
          </div>
          <h4 className="home__position">Lecturer</h4>
          <div className="home__info">
            <LocationOnIcon></LocationOnIcon> Paris, Sorbonne University
          </div>
          <h2 className="home__section">Skills</h2>
          <Row>
            <Col>
              <h5 className="home__subsection">Data Science</h5>
              <div className="home__icons">
                <SiPython /> <SiPytorch /> <SiPandas /> <SiScikitlearn />
              </div>
            </Col>
            <Col>
              <h5 className="home__subsection">Software</h5>
              <div className="home__icons">
                <SiGit /> <SiReact /> <SiJavascript /> <SiAzuredevops />
              </div>
            </Col>
          </Row>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
